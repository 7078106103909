body {
  margin: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
}

.app {
  width: 90%;
  max-width: 400px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  display: flex;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.listItem-checkbox {
  padding: 0.25rem;
}

.listItem-label {
  flex: 1;
  padding: 0.25rem;
}

.listItem-delete {
  padding: 0.25rem;
}

form {
  display: flex;
  width: 100%;
}

input[type=text] {
  flex:1;
  display:block;
  min-width:0;
  outline: 0;

  border: 2px solid darkgrey;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;

  font-size: inherit;
}

input[type=text]:focus {
  border-color: darkgrey;
}

/* Loading Indicator */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: black;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.loading-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
